import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query LinkRollQuery {
        pageData {
          linkroll {
            href
            subtitle
            title
          }
        }
      }
    `}
  render={data => {
    return (
      <section className="linkroll">
        <div className="container">
          <h2 className="title has-text-centered">Recommended Sites</h2>
          <div className="tile is-ancestor linkroll__tiles">
            {data.pageData.linkroll.map((link, i) => {
              if (i > 7) {
                return (
                  <div key={link.href} className="tile is-parent">
                    <a href={link.href} target="_blank" rel="noopener noreferrer" className="tile is-child notification is-link">
                      <p className="title">{link.title}</p>
                      <p className="subtitle">{link.subtitle}</p>
                    </a>
                  </div>
                )
              } else {
                return ``
              }
            })}
          </div>
          <div>
            <Link className="is-pulled-right is-size-5" to="links">View more recommended websites</Link>
          </div>
        </div>
        <style jsx='jsx'>{`
          .linkroll {
            margin-top: 6em;
          }
          .linkroll > div {
            border-top: 1px solid #555;
            padding-top: 5em;
          }
          .linkroll__tiles {
            flex-wrap: wrap;
            padding-top: 3em;
          }
          .linkroll__tiles > div {
            flex-basis: 33%;
          }
          .linkroll h2.title {
            font-size: 2.3em;
          }
          .linkroll .title {
            font-size: 1.45em;
          }
        `}
        </style>
    </section>
    )
  }}
  />
  )