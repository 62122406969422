import React from "react"
import styles from "../components/hero.module.scss"
import Header from "../components/header"
import Img from "gatsby-image"

const Hero = ({ children, ...props }) => {
  return (
    <>
    <section className={`hero is-dark is-fullheight ${styles.hero}`}>
      <Header hero="full" />
      <div className={`hero-body ${styles.heroBody}`}>
        <div className="container has-text-centered">
          {children}
        </div>
      </div>
      <Img
        title="Church in Cypress, California"
        alt="Church in Cypress, California"
        sizes={props.sizes}
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          width: `100%`,
          height: `100%`
        }}
      />
    </section>
  </>
  )
}

export default Hero