import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import "../styles/global.scss"
import LinkRoll from "../components/LinkRoll"
import { graphql, Link } from "gatsby"
import {createMarkup} from "../helpers/helpers"
import { FaNewspaper, FaPray, FaEnvelope } from "react-icons/fa"

const IndexPage = ({ data }) => {

  const { 
    pageData: {
      home
    },
    heroImage: {
      sizes
    }
  } = data

  return (
    <Layout hero="full">
      <SEO title="The church in Cypress, California" />
      {/* <div class="popup" id="myheader" role="alert"><center class="text-popup">Read our response to the COVID-19 pandemic. <a href="/infocovid19" type="button" class="pop-alert">Read</a></center></div> */}
      <Hero sizes={sizes} size="full">
        <h1 className="title" dangerouslySetInnerHTML={createMarkup(home.heroTitle)} />
        <h2 className="subtitle" dangerouslySetInnerHTML={createMarkup(home.heroSubtitle)} />
      </Hero>
      <section className="section intro">
        <div className="container">
        <p className="intro-text" dangerouslySetInnerHTML={createMarkup(home.introText)} />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <Link to="/news">
                <article className="tile is-child notification is-success">
                  <p className="title">
                    <span className="react-icon left">
                      <FaNewspaper />
                    </span>
                    Church News</p>
                  <p className="subtitle">See what's happening each week in the church in Cypress</p>
                </article>
              </Link>
            </div>
            <div className="tile is-parent">
              <Link to="/testimonies">
                <article className="tile is-child notification is-success">
                  <p className="title">
                    <span className="react-icon left">
                      <FaPray />
                    </span>
                  Testimonies</p>
                  <p className="subtitle">Read the experiences of those who meet with the church in Cypress</p>
                </article>
              </Link>
            </div>
            <div className="tile is-parent">
              <Link to="/contact">
                <article className="tile is-child notification is-success">
                  <p className="title">
                    <span className="react-icon left">
                      <FaEnvelope />
                    </span>
                    Contact Us</p>
                  <p className="subtitle">Interested in meeting with us? Reach out!</p>
                </article>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <LinkRoll />
      <style jsx='jsx'>{`
        .intro-text span {
          display: block;
          margin-bottom: 2em;
        }
        section.intro {
          font-size: 2em;
          font-family: 'Roboto Slab';
          margin: 4em auto;
        }
        @media (max-width: 375px) {
          section.intro {
            font-size: 1.2em;
            margin: 2em auto;
            padding-left: 0.8em;
            padding-right: 0.8em;
          }
        }
      `}</style>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query homePageQuery {
    pageData {
      home {
        heroSubtitle
        heroTitle
        introText
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/hero-bg/" }}) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
  }
`